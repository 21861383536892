import React from "react"
import { Link } from "gatsby"
import { Box, Badge, Image, Grid } from "@chakra-ui/core"
import Layout from "../../../components/layoutSurpriseCustom"
import BgImg from "../../../images/sermons.jpg"
import LoveSong from "../../../images/Love_Song.jpg"
import LoveSongs from "../../../images/LoveSongsSeries.jpg"
import Radical from "../../../images/radicalLectures.jpg"
import Vision from "../../../images/visionSeries.jpg"
import Judges from "../../../images/judgesSeries.jpg"
import Christmas from "../../../images/christmasSeries.jpg"
import WhenPigsFly from "../../../images/whenPigsFly_series.jpg"
import FearNot from "../../../images/fearnotSeries.jpg"
import WhenPeoplePray from "../../../images/whenPeoplePray_series.jpg"
import TogetherisBetter from "../../../images/togetherSeries.jpg"
import Colossians from "../../../images/ColossiansSeries.jpg"
import NowWhat from "../../../images/nowWhatSeries.jpg"
import HolidayOnItsHead from "../../../images/holidayOnItsHead_xmas.jpg"
import resetSeries from "../../../images/resetSeries.jpg"
import Searching from "../../../images/searchingSeries.jpg"
import PursuitofHappiness from "../../../images/POHSquareSeries.jpeg"
import SevenThings from "../../../images/SevenThings_Series.jpeg"
import INUWSeries from "../../../images/INUWSeries.jpg"
import Imperatives from "../../../images/Imperatives_Series.jpeg"
import Romans from "../../../images/Romans_Series.jpeg"
import Practice from "../../../images/practiceTitle.jpeg"
import ChristmasComes from "../../../images/ChristmasComesSquare.jpeg"
import Rise from "../../../images/Easter2022.jpg"
import Partnerships from "../../../images/PartnershipsTitle.jpg"
import CounterCulture from "../../../images/counterCulture_title.jpeg"
import ThePuzzle from "../../../images/ThePuzzle_square.jpg"
import JesusinGenesis from "../../../images/JesusinGenesis_Square.jpg"
import TBT from "../../../images/TheBig10_Square.jpg"
import MMM from "../../../images/MMM_Square.jpg"
import TH from "../../../images/TH_Square.jpg"
import GH from "../../../images/GH_Square.jpg"
import More from "../../../images/moreSermons.jpg"
import AWC from "../../../images/AWC_Square.jpg"
import LTD from "../../../images/LivingtheDream_Square.jpg"
import PerfectChristmas from "../../../images/PerfectChristmas_Square.jpg"
import CrossingRivers from "../../../images/CrossingRivers_Square.jpg"
import TheWeek from "../../../images/TWTCTW_Square.jpg"
import Hebrews from "../../../images/Hebrews_Square.jpg"
import Relationships from "../../../images/Relationships_Square.jpg"
import WhyChurch from "../../../images/WhyChurch_Square.jpg"
import LivinginBabylon from "../../../images/LivinginBabylon_Square.jpg"
import SEO from "../../../components/seo"
import { css } from "@emotion/core"

function SermonBox({
  imageUrl,
  imageAlt,
  sermonTitle,
  sermonLink,
  date,
  badge,
  pastor,
  sermonSeries,
  badgeColor,
}) {
  return (
    <Box
      as={Link}
      to={sermonLink}
      w="auto"
      h="auto"
      borderWidth="1px"
      rounded="lg"
      overflow="hidden"
      bg="#edf2f7"
      boxShadow="md"
      css={css`
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
        }
      `}
    >
      <Image src={imageUrl} alt={imageAlt} w="100%" />

      <Box p="6">
        <Box alignItems="baseline">
          <Badge rounded="lg" px="8" py="2" variantColor={badgeColor} mt="0">
            {badge}
          </Badge>
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            mt="3"
          >
            {sermonSeries} - {pastor}
          </Box>
        </Box>
        <Box
          fontWeight="bold"
          as="h2"
          lineHeight="tight"
          isTruncated
          color="gray.900"
        >
          {sermonTitle}
        </Box>

        <Box color="gray.600" fontSize="sm">
          {date}
        </Box>
      </Box>
    </Box>
  )
}

function SermonBoxExternal({
  imageUrl,
  imageAlt,
  sermonTitle,
  sermonLink,
  date,
  badge,
  pastor,
  sermonSeries,
  badgeColor,
}) {
  return (
    <Box
      as="a"
      href={sermonLink}
      w="auto"
      h="auto"
      borderWidth="1px"
      rounded="lg"
      overflow="hidden"
      bg="#edf2f7"
      boxShadow="md"
      css={css`
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
        }
      `}
    >
      <Image src={imageUrl} alt={imageAlt} w="100%" />

      <Box p="6">
        <Box alignItems="baseline">
          <Badge rounded="lg" px="8" py="2" variantColor={badgeColor} mt="0">
            {badge}
          </Badge>
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            mt="3"
          >
            {sermonSeries} &bull; {pastor}
          </Box>
        </Box>
        <Box
          fontWeight="bold"
          as="h2"
          lineHeight="tight"
          isTruncated
          color="gray.900"
        >
          {sermonTitle}
        </Box>

        <Box color="gray.600" fontSize="sm">
          {date}
        </Box>
      </Box>
    </Box>
  )
}

const IndexPage = () => (
  <Layout
    ImgSrc={BgImg}
    MainText="Sermons"
    SecondText="Check out our previous sermons right here."
  >
    <SEO title="Sermons" />
    <Grid
      templateColumns={[
        "repeat(1, 1fr)",
        "repeat(1, 1fr)",
        "repeat(2, 1fr)",
        "repeat(3, 1fr)",
      ]}
      gap={10}
    >
      <SermonBox
        imageUrl={LivinginBabylon}
        imageAlt="Living in Babylon title image"
        sermonTitle="Living in Babylon"
        pastor="Present"
        sermonSeries="October 6th"
        sermonLink="/surprise/sermons/living-in-babylon"
        badge="current series"
        badgeColor="teal"
      />
      <SermonBox
        imageUrl={WhyChurch}
        imageAlt="Why Church title image"
        sermonTitle="Why Church?"
        pastor="September 29th"
        sermonSeries="September 1st"
        sermonLink="/surprise/sermons/why-church"
      />
      <SermonBox
        imageUrl={Relationships}
        imageAlt="Relationships title image"
        sermonTitle="Relationships"
        pastor="August 25th"
        sermonSeries="August 4th"
        sermonLink="/surprise/sermons/relationships"
      />
      <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews title image"
        sermonTitle="Hebrews"
        pastor="July 28th"
        sermonSeries="April 7th"
        sermonLink="/surprise/sermons/hebrews"
      />
      <SermonBox
        imageUrl={TheWeek}
        imageAlt="The Week That Changed the World title image"
        sermonTitle="The Week That Changed the World"
        pastor="March 31st"
        sermonSeries="March 10th"
        sermonLink="/surprise/sermons/the-week-that-changed-the-world"
      />
      <SermonBox
        imageUrl={CrossingRivers}
        imageAlt="Crossing Rivers title image"
        sermonTitle="Crossing Rivers"
        pastor="March 3rd"
        sermonSeries="January 7th"
        sermonLink="/surprise/sermons/crossing-rivers"
      />
      <SermonBox
        imageUrl={PerfectChristmas}
        imageAlt="Searching for the Perfect Christmas title image"
        sermonTitle="Searching for the Perfect Christmas"
        pastor="December 24th"
        sermonSeries="December 3rd"
        sermonLink="/surprise/sermons/the-perfect-christmas"
      />
      <SermonBox
        imageUrl={TBT}
        imageAlt="The Big 10 sermon title image"
        sermonTitle="The Big 10"
        pastor="November 26th"
        sermonSeries="September 24th"
        sermonLink="/surprise/sermons/the-big-ten"
      />
      <SermonBox
        imageUrl={GH}
        imageAlt="Greatest Hits sermon series image"
        sermonTitle="Greatest Hits"
        pastor="September 17th"
        sermonSeries="July 16th"
        sermonLink="/surprise/sermons/greatest-hits"
      />
      <SermonBox
        imageUrl={TH}
        imageAlt="Touching Holiness sermon series image"
        sermonTitle="Touching Holiness"
        pastor="July 9th"
        sermonSeries="April 2nd"
        sermonLink="/surprise/sermons/touching-holiness"
      />
      <SermonBox
        imageUrl={MMM}
        imageAlt="Marriage, Money, and Minions sermon series image"
        sermonTitle="Marriage, Money, and Minions"
        pastor="March 26th"
        sermonSeries="February 19th"
        sermonLink="/surprise/sermons/marriage-money-minions"
      />
      <SermonBox
        imageUrl={LTD}
        imageAlt="Living the Dream sermon series image"
        sermonTitle="Living the Dream"
        pastor="February 12th"
        sermonSeries="January 8th"
        sermonLink="/surprise/sermons/living-the-dream"
      />
      <SermonBox
        imageUrl={AWC}
        imageAlt="A Winning Christmas sermon series image"
        sermonTitle="A Winning Christmas"
        pastor="December 24th"
        sermonSeries="December 4th"
        sermonLink="/surprise/sermons/a-winning-christmas"
      />
      <SermonBox
        imageUrl={JesusinGenesis}
        imageAlt="Jesus in Genesis sermon series image"
        sermonTitle="Jesus in Genesis"
        pastor="November 27th"
        sermonSeries="October 2nd"
        sermonLink="/surprise/sermons/jesus-in-genesis"
      />
      <SermonBox
        imageUrl={ThePuzzle}
        imageAlt="The Puzzle sermon series image"
        sermonTitle="The Puzzle"
        pastor="September 25th"
        sermonSeries="September 4th"
        sermonLink="/surprise/sermons/the-puzzle"
      />
    </Grid>
  </Layout>
)

export default IndexPage
